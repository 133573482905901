import Vue from 'vue';
import Vuex from 'vuex';
// Get a RTDB instance
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/auth';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const firebaseConfig = {
  apiKey: 'AIzaSyBZbXLy6s0D6uCrBEcFOLlpBwzm93FPcT8',
  authDomain: 'wedding-99982.firebaseapp.com',
  databaseURL: 'https://wedding-99982.firebaseio.com',
  projectId: 'wedding-99982',
  storageBucket: 'wedding-99982.appspot.com',
  messagingSenderId: '826784259801',
  appId: '1:826784259801:web:0e538f5aedd8207029a8c8',
};
firebase.initializeApp(firebaseConfig);
const db = firebase.database();
const storageRef = firebase.storage().ref('canvas');

Vue.use(Vuex);
const defaultFormData = {
  rsvp: {
    name: '',
    isJoining: 'no',
    groupSize: 1,
    isSent: false,
    invitationCode: null,
    isValid: false,
    isLoading: false,
  },
  message: {
    name: '',
    message: '',
    invitationCode: null,
    isValid: false,
    canvasPath: null,
    canvasPreviewUrl: null,
  },
};

const state = {
  uid: '',
  invitationCode: null,
  user: { name: '' },
  rsvpFormData: defaultFormData.rsvp,
  messageFormData: defaultFormData.message,
  messages: [],
  allUsersMessages: [],
  floorPlan: { tableCode: '', tableColor: '' },
  dateInfo: { weddingDateDiff: 0, isWeddingDay: true, isRsvpEnabled: false },
};

const getters = {
  rsvpFormData: (state) => state.rsvpFormData,
  messageFormData: (state) => state.messageFormData,
  messages: (state) => state.messages,
  user: (state) => state.user,
  floorPlan: (state) => state.floorPlan,
  allUsersMessages: (state) => state.allUsersMessages,
  dateInfo: (state) => state.dateInfo,
  isSignedIn: (state) => state.uid !== null && state.uid.length > 0,
  isValidCode: (state) => state.invitationCode !== null && state.invitationCode.length > 0,
};

const storeCode = (invitationCode) => {
  localStorage.invitationCode = invitationCode;
};

function cachedInvitationCode() {
  return localStorage.invitationCode;
}

function currentInvitationCode() {
  return state.invitationCode ?? cachedInvitationCode();
}

const actions = {
  async calculateDateInfo({ commit }) {
    const now = new Date();
    const rsvpLastDate = new Date('7-Mar-2022');
    const weddingDate = new Date('15-Mar-2022');
    const datesAreOnSameDay = (first, second) => first.getFullYear() === second.getFullYear()
      && first.getMonth() === second.getMonth()
      && first.getDate() === second.getDate();
    const result = {
      weddingDateDiff: weddingDate.getTime() - now.getTime(),
      isWeddingDay: datesAreOnSameDay(now, weddingDate),
      isRsvpEnabled: rsvpLastDate.getTime() - now.getTime() > 0,
    };
    commit('updateDateInfo', result);
  },
  async validateCode({ commit }, code) {
    if (code !== undefined) {
      const upperCased = code.toUpperCase();
      db.ref('users').once('value', (userSnapshot) => {
        const isValid = userSnapshot.hasChild(upperCased);
        commit('validate', isValid);
        if (isValid) {
          commit('updateInvitationCode', upperCased);
        }
      });
    }
  },
  async signInAnonymously({ commit }) {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const { uid } = user;
        commit('firebaseLoggedIn', uid);
      }
    });
    firebase.auth().signInAnonymously();
  },
  async fetchRSVP({ commit }, code) {
    // localStorage.clear(); // DEBUG
    const invitationCode = code ?? cachedInvitationCode();
    commit('markRSVPLoading', true);
    db.ref('users').once('value', (userSnapshot) => {
      if (invitationCode !== undefined && userSnapshot.hasChild(invitationCode)) {
        const user = userSnapshot.child(invitationCode).val();
        commit('validate', true);
        commit('updateUser', user);
        commit('updateInvitationCode', invitationCode);
        db.ref('rsvp/'.concat(invitationCode)).once('value', (rsvpSnapshot) => {
          const formData = rsvpSnapshot.val() ?? defaultFormData.rsvp;
          formData.invitationCode = invitationCode;
          formData.name = user.name;
          commit('updateRsvpFormData', formData);
          commit('markRSVPLoading', false);
        });
      } else {
        localStorage.clear();
        commit('updateRsvpFormData', defaultFormData.rsvp);
        commit('markRSVPLoading', false);
      }
    });
  },
  async sendRSVP({ commit }, formData) {
    const code = formData.invitationCode;
    const dataToStore = JSON.parse(JSON.stringify(formData));
    dataToStore.isSent = true;
    dataToStore.updatedAt = new Date().toISOString();
    delete dataToStore.invitationCode;
    commit('markRSVPLoading', true);
    db.ref('rsvp/'.concat(code))
      .set(dataToStore)
      .then(() => {
        storeCode(code);
        commit('markRSVPAsSent', formData);
        commit('markRSVPLoading', false);
      });
  },
  async fetchUser({ commit }, code) {
    const invitationCode = code ?? cachedInvitationCode();
    db.ref('users/'.concat(invitationCode)).once('value', (snapshot) => {
      const user = snapshot.val();
      if (user !== null) {
        commit('updateUser', user);
      }
    });
  },
  async fetchFloorPlan({ commit }, code) {
    const invitationCode = code ?? cachedInvitationCode();
    db.ref('floorPlan/'.concat(invitationCode)).once('value', (snapshot) => {
      const floorPlan = snapshot.val();
      if (floorPlan !== null) {
        commit('updateFloorPlan', floorPlan);
      }
    });
  },
  async fetchMessages({ commit }, code) {
    // localStorage.clear(); // DEBUG
    const invitationCode = code ?? cachedInvitationCode();
    if (invitationCode === undefined) {
      commit('updateMessages', []);
    } else {
      db.ref('users').once('value', (userSnapshot) => {
        if (userSnapshot.hasChild(invitationCode)) {
          commit('validate', true);
          commit('updateInvitationCode', invitationCode);
          commit('updateName', userSnapshot.child(invitationCode).val().name);
        }
      });
    }
  },
  async sendMessages({ commit }, formData) {
    const { messages } = state;
    const newMessage = {
      name: formData.name,
      message: formData.message,
      canvasPath: formData.canvasPath,
    };
    newMessage.updatedAt = new Date().toISOString();
    messages.unshift(newMessage);
    db.ref('messages/'.concat(currentInvitationCode()))
      .set(messages)
      .then(() => {
        commit('updateMessages', messages);
        commit('clearMessageForm');
      });
  },
  async bindMessages({ commit }, code) {
    const invitationCode = code ?? cachedInvitationCode();
    if (invitationCode !== undefined) {
      db.ref('messages/'.concat(invitationCode)).on('value', (snapshot) => {
        const messages = snapshot.val();
        if (messages !== null) {
          commit('updateMessages', messages);
        }
      });
    }
  },
  async bindAllUsersMessages({ commit }, code) {
    const invitationCode = code ?? cachedInvitationCode();
    if (invitationCode !== undefined) {
      // Verify user
      db.ref('users/'.concat(invitationCode)).once('value', (userSnapshot) => {
        const isValidUser = userSnapshot.hasChild('name');
        if (isValidUser) {
          db.ref('messages').on('value', (snapshot) => {
            const allUsersMessages = snapshot.val();
            const keys = Object.keys(allUsersMessages);
            let messages = [];
            keys.forEach((item) => {
              messages = messages.concat(allUsersMessages[item]);
            });
            if (messages.length > 0) {
              commit('updateAllUsersMessages', messages);
            }
          });
        }
      });
    }
  },
  async uploadCanvasImage({ commit }, { url, onCompletion }) {
    const blob = await fetch(url).then((r) => r.blob());
    const invitationCode = cachedInvitationCode();
    // Skip when unrecognized
    if (invitationCode === undefined || invitationCode === null) { return; }
    const remotePath = invitationCode.concat('/', new Date().toISOString(), '.png');
    storageRef.child(remotePath).put(blob).then(() => {
      commit('updateFileInMessageForm', { canvasPreviewUrl: url, canvasPath: remotePath });
      onCompletion(true);
    }).catch(() => {
      onCompletion(false);
    });
  },
  async removeCurrentImage({ commit }) {
    const currentPath = state.messageFormData.canvasPath;
    if (currentPath === null) { return; }
    commit('updateFileInMessageForm', { canvasPreviewUrl: null, canvasPath: null });
    storageRef.child(currentPath).delete();
  },
  async getCanvasUrl(_, path) {
    return storageRef.child(path).getDownloadURL();
  },
};

const mutations = {
  firebaseLoggedIn: (state, uid) => {
    state.uid = uid;
  },
  updateFileInMessageForm: (state, data) => {
    state.messageFormData.canvasPath = data.canvasPath;
    state.messageFormData.canvasPreviewUrl = data.canvasPreviewUrl;
  },
  updateDateInfo: (state, result) => {
    state.dateInfo = result;
  },
  markRSVPAsSent: (state, formData) => {
    const updatedData = formData;
    updatedData.isSent = true;
    state.rsvpFormData = updatedData;
  },
  markRSVPLoading: (state, isLoading) => {
    state.rsvpFormData.isLoading = isLoading;
  },
  updateRsvpFormData: (state, formData) => {
    state.rsvpFormData = formData;
  },
  validate: (state, isValid) => {
    state.rsvpFormData.isValid = isValid;
    state.messageFormData.isValid = isValid;
  },
  updateInvitationCode: (state, code) => {
    state.invitationCode = code;
    state.rsvpFormData.invitationCode = code;
    state.messageFormData.invitationCode = code;
    storeCode(code);
  },
  updateUser: (state, user) => {
    state.user = user;
  },
  updateName: (state, name) => {
    state.messageFormData.name = name;
  },
  clearMessageForm: (state) => {
    state.messageFormData.message = '';
    state.messageFormData.canvasPath = null;
    state.messageFormData.canvasPreviewUrl = null;
  },
  updateMessages: (state, messages) => {
    state.messages = messages.sort((a, b) => new Date(b.updatedAt).getTime()
      - new Date(a.updatedAt).getTime());
  },
  updateFloorPlan: (state, floorPlan) => {
    state.floorPlan = floorPlan;
  },
  updateAllUsersMessages: (state, messages) => {
    state.allUsersMessages = messages.sort((a, b) => new Date(b.updatedAt).getTime()
    - new Date(a.updatedAt).getTime());
  },
};

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
});
