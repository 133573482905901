<template>
  <div class="drawing-canvas overlay">
    <font-awesome-icon
        class="close-button"
        v-on:click="onClose"
        icon="times"
        size="2x"
      />
    <div
      class="tools"
      v-bind:style="{
        top: configTools.top + 'px',
        left: configTools.left + 'px',
        width: configTools.width + 'px',
        height: configTools.height + 'px',
      }"
    >
      <div
        v-bind:class="{
          'manage-canvas-portrait': isPortrait,
          'manage-canvas-landscape': !isPortrait
        }"
      >
        <vue-slider
          v-model="lineSize"
          v-bind:class="{
            'slider-portrait': isPortrait,
            'slider-landscape': !isPortrait
          }"
          :width="isPortrait ? 100 : 8"
          :height="!isPortrait ? 100 : 8"
          :min="1"
          :max="25"
          :tooltip="'active'"
          :tooltip-placement="isPortrait ? ['top'] : ['left']"
          :tooltip-formatter="'{value}px'"
          :direction="isPortrait ? 'ltr' : 'ttb'"
        />
        <v-swatches
          class="color-button"
          v-model="penColor"
          :popover-x="isPortrait ? 'left' : 'right'"
          :popover-y="isPortrait ? 'top' : 'bottom'"
          swatches="text-advanced"
          :trigger-style="{
            width: '32px',
            height: '32px',
            borderRadius: '4px'
          }"
          :swatch-style="{
            height: '24px',
            width: '24px'
          }"
        />
        <font-awesome-icon
          class="pen-button"
          v-bind:class="{selected: isBrush(), inactiveButton: !isBrush()}"
          v-on:click="onSelectPen"
          icon="pen"
          size="1x"
        />
        <font-awesome-icon
          class="eraser-button"
          v-bind:class="{selected: !isBrush(), inactiveButton: isBrush()}"
          v-on:click="onSelectEraser"
          icon="eraser"
          size="1x"
        />
        <font-awesome-icon
          class="undo-button"
          v-bind:class="{ 'tools-disabled': (lines.length === 0) }"
          v-on:click="handleUndo"
          icon="undo-alt"
          size="1x"
        />
        <font-awesome-icon
          class="redo-button"
          v-bind:class="{ 'tools-disabled': (undoLines.length === 0) }"
          v-on:click="handleRedo"
          icon="redo-alt"
          size="1x"
        />
      </div>
      <div class="manage-version center">
        <button class="button" v-on:click="onUpload">บันทึกการ์ดอวยพร</button>
        <button class="button"
          :disabled="lines.length === 0"
          v-on:click="onClear">
          ลบรูปการ์ดที่เขียนไว้
        </button>
      </div>
    </div>
    <!-- <h1 class="center error">มีข้อผิดพลาด</h1> -->
    <div v-if="isLoading" class="loading overlay">
      <h1 class="center">โปรดรอสักครู่</h1>
    </div>
    <v-stage
      class="canvas-stage"
      v-bind:class="{ 'canvas-portrait': isPortrait, 'canvas-landscape': !isPortrait }"
      ref="stage"
      :config="configKonva"
      @mousedown="handleTouchStart"
      @touchstart="handleTouchStart"
      @mouseup="handleTouchEnd"
      @touchend="handleTouchEnd"
      @mousemove="handleTouchMove"
      @touchmove="handleTouchMove"
    >
      <v-layer ref="layer0">
        <v-image ref="backgroundImage">
        </v-image>
      </v-layer>
      <v-layer ref="layer">
      </v-layer>
    </v-stage>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Konva from 'konva';
import VSwatches from 'vue-swatches';
import 'vue-swatches/dist/vue-swatches.css';
import VueSlider from 'vue-slider-component';
import '../assets/slider-style.css';

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;
const minSize = Math.min(windowWidth, windowHeight);
let layer;
let stage;

export default {
  name: 'DrawingCanvas',
  components: { VSwatches, VueSlider },
  methods: {
    ...mapActions(['uploadCanvasImage', 'removeCurrentImage']),
    onClose(e) {
      e.preventDefault();
      this.$emit('close');
    },
    handleRedraw() {
      layer.find('Line').destroy();
      this.lines.forEach((line) => layer.add(line));
      layer.batchDraw();
    },
    clearCanvas() {
      this.lines = [];
      this.undoLines = [];
      this.handleRedraw();
      this.onSelectPen();
    },
    clearUploadedImage() {
      this.removeCurrentImage();
    },
    onClear(e) {
      e.preventDefault();
      const options = {
        okText: 'ยืนยัน',
        cancelText: 'ยกเลิก',
      };
      this.$dialog
        .confirm('ยืนยันการลบทั้งหมด', options)
        .then(() => {
          this.clearCanvas();
          this.clearUploadedImage();
        });
    },
    onUpload(e) {
      e.preventDefault();
      this.isLoading = true;
      stage.toDataURL({
        callback: (url) => {
          this.clearUploadedImage();
          this.uploadCanvasImage({
            url,
            onCompletion: (isSuccess) => {
              this.isLoading = false;
              if (isSuccess) {
                this.clearCanvas();
                this.onClose(e);
              }
              this.isError = true;
            },
          });
        },
      });
    },
    onSelectPen() {
      this.mode = 'brush';
    },
    onSelectEraser() {
      this.mode = 'eraser';
    },
    handleTouchStart() {
      this.isPaint = true;
      const pos = stage.getPointerPosition();
      this.lastLine = new Konva.Line({
        stroke: this.penColor,
        strokeWidth: this.mode === 'brush' ? this.lineSize : this.eraserSize,
        globalCompositeOperation: this.mode === 'brush' ? 'source-over' : 'destination-out',
        points: [pos.x, pos.y],
        lineJoin: 'round',
        lineCap: 'round',
      });
      layer.add(this.lastLine);
      this.undoLines = [];
    },
    handleTouchEnd() {
      this.isPaint = false;
      this.lines.push(this.lastLine);
    },
    handleTouchMove() {
      if (!this.isPaint) {
        return;
      }
      const pos = stage.getPointerPosition();
      const newPoints = this.lastLine.points().concat([pos.x, pos.y]);
      this.lastLine.points(newPoints);
      layer.batchDraw();
    },
    handleUndo() {
      const undoLine = this.lines.pop();
      if (undoLine === undefined) { return; }
      this.undoLines.push(undoLine);
      this.handleRedraw();
    },
    handleRedo() {
      const redoLine = this.undoLines.pop();
      if (redoLine === undefined) { return; }
      this.lines.push(redoLine);
      this.handleRedraw();
    },
    isBrush() {
      return this.mode === 'brush';
    },
    handleScreenResize() {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const isPortrait = width < height;
      const portraitMarginTop = 50;
      this.isPortrait = isPortrait;
      if (isPortrait) {
        const top = minSize + portraitMarginTop;
        this.configTools = {
          top,
          left: 0,
          width,
          height: height - top,
        };
      } else {
        this.configTools = {
          top: 0,
          left: minSize,
          width: width - minSize,
          height,
        };
      }
    },
  },
  data() {
    return {
      isLoading: false,
      configKonva: {
        width: minSize,
        height: minSize,
      },
      isPaint: true,
      mode: 'brush',
      lines: [],
      undoLines: [],
      lastLine: null,
      lineSize: 5,
      eraserSize: 10,
      penColor: '#000000',
      isPortrait: true,
      configTools: {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
      },
    };
  },
  mounted() {
    window.addEventListener(
      'resize',
      this.handleScreenResize,
    );
    layer = this.$refs.layer.getNode();
    stage = this.$refs.stage.getStage();
    const backgroundImage = this.$refs.backgroundImage.getNode();
    backgroundImage.setAttrs({
      x: 0,
      y: 0,
      width: minSize,
      height: minSize,
      fill: 'white',
    });
    this.handleScreenResize();
  },
};
</script>

<style scoped>
.drawing-canvas {
  background: #800000;
  margin: 0;
}
h1 {
  color: #ffffff;
  width: 100%;
}
.overlay {
  z-index: 1;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.loading {
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
}
.error {
  color: #ff0000;
}
.selected {
  color: #800000;
}
.inactiveButton {
  color: #ffffff;
}
.close-button {
  position: absolute;
  top: 0;
  right: 8px;
  color: #ffffff;
  padding: 8px 0;
  width: 32px;
  height: 32px;
  z-index: 100;
}
.pen-button, .eraser-button,
.undo-button, .redo-button {
  display: inline-block;
  position: relative;
  margin: 4px;
  padding: 4px;
  width: 24px;
  height: 24px;
  background: rgba(232, 160, 174, 0.8);
  border-radius: 4px;
}
.tools-disabled {
  opacity: 0.5;
}
.undo-button, .redo-button {
  color: #fff;
}
.undo-button:active, .redo-button:active {
  color: #800000;
}
.vue-swatches.color-button {
  top: -2px;
  margin: 4px;
  height: 32px;
}
.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.canvas-portrait {
  margin: 0;
  position: absolute;
  top: 50px;
  left: 0;
}
.canvas-landscape {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.tools {
  position: absolute;
  background-image: url('../assets/drawing-tools-bg.svg');
  background-repeat: no-repeat;
  background-position: center;
  z-index: 99;
}
.manage-version {
  width: 100%;
  width: calc(100% - 100px);
}
.manage-version .button {
  margin-top: 16px;
  width: 100%;
  height: 38px;
  border-radius: 19px;
  font-family: 'Kanit', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;

  background: rgba(237, 119, 125, 0.7);
  border: 1px solid transparent;
  color: #fff;
}
.manage-version .button:active {
  color: #800000;
}
.manage-version .button:disabled {
  opacity: 0.5;
}
.manage-canvas-portrait {
  height: 40px;
  top: 0;
  right: 0;
  margin-top: 8px;
  margin-right: 16px;
  text-align: right;
}
.manage-canvas-landscape {
  width: 40px;
  top: 0;
  left: 0;
  margin: 8px;
}
.slider-portrait {
  position: absolute;
  top: 12px;
  right: 220px;
  margin: 4px;
}
.slider-landscape {
  position: absolute;
  top: 230px;
  left: 12px;
  margin: 4px;
}
</style>
<style>
.vue-swatches__row {
  height: 24px;
}
.dg-btn--cancel {
  background-color: #800000;
}
.dg-btn--ok {
  color: #800000;
  border-color: #800000;
}
</style>
