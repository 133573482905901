<template>
  <div class="welcome">
    <slider
      id="slider"
      ref="slider"
      :options="options"
    >
      <!-- slideritem wrapped package with the components you need -->
      <slideritem
        v-for="(item, index) in someList"
        :key="index"
        :style="item.style">
        <img class="image-fill" :src=item.html>
      </slideritem>
      <!-- Customizable loading -->
      <div slot="loading">loading...</div>
    </slider>
    <!-- <div class="arrow bounce">
      <font-awesome-icon icon="arrow-down" size="2x" />
    </div> -->
  </div>
</template>

<script>
import { slider, slideritem } from 'vue-concise-slider';
// import image1 from '../assets/IMG_0731.jpeg';
// import image2 from '../assets/IMG_0845.jpeg';
// import image3 from '../assets/IMG_0907.jpeg';
// import image4 from '../assets/_PWK2661.jpeg';

import image1 from '../assets/gallery/IMG_0635.jpeg';
import image2 from '../assets/gallery/IMG_0656.jpeg';
import image3 from '../assets/gallery/IMG_0708.jpeg';
import image4 from '../assets/gallery/IMG_0720.jpeg';
import image5 from '../assets/gallery/IMG_0731.jpeg';
import image6 from '../assets/gallery/IMG_0744.jpeg';
import image7 from '../assets/gallery/IMG_0746.jpeg';
import image8 from '../assets/gallery/IMG_0801.jpeg';
import image9 from '../assets/gallery/IMG_0803.jpeg';
import image10 from '../assets/gallery/IMG_0838.jpeg';
import image11 from '../assets/gallery/IMG_0845.jpeg';
import image12 from '../assets/gallery/IMG_0857.jpeg';
import image13 from '../assets/gallery/IMG_0867.jpeg';
import image14 from '../assets/gallery/IMG_0874.jpeg';
import image15 from '../assets/gallery/IMG_0876.jpeg';
import image16 from '../assets/gallery/IMG_0900.jpeg';
import image17 from '../assets/gallery/IMG_0907.jpeg';
import image18 from '../assets/gallery/IMG_1043.jpeg';
import image19 from '../assets/gallery/IMG_1094.jpeg';
import image20 from '../assets/gallery/IMG_1133.jpeg';
import image21 from '../assets/gallery/_PWK2484_-PSedit_.jpeg';
import image22 from '../assets/gallery/_PWK2552_-PSedit_.jpeg';
import image23 from '../assets/gallery/_PWK2570_-PSedit_.jpeg';
import image24 from '../assets/gallery/_PWK2624_-PSedit_.jpeg';
import image25 from '../assets/gallery/_PWK2661_-PSedit_.jpeg';
import image26 from '../assets/gallery/_PWK2704_-PSedit_.jpeg';
import image27 from '../assets/gallery/_PWK2739_-PSedit_.jpeg';
import image28 from '../assets/gallery/_PWK2749_-PSedit_.jpeg';

export default {
  name: 'Welcome',
  data() {
    return {
      someList: [
        {
          html: image1,
          style: {
            background: '#000',
          },
        },
        {
          html: image2,
          style: {
            background: '#000',
          },
        },
        {
          html: image3,
          style: {
            background: '#000',
          },
        },
        {
          html: image4,
          style: {
            background: '#000',
          },
        },
        {
          html: image5,
          style: {
            background: '#000',
          },
        },
        {
          html: image6,
          style: {
            background: '#000',
          },
        },
        {
          html: image7,
          style: {
            background: '#000',
          },
        },
        {
          html: image8,
          style: {
            background: '#000',
          },
        },
        {
          html: image9,
          style: {
            background: '#000',
          },
        },
        {
          html: image10,
          style: {
            background: '#000',
          },
        },
        {
          html: image11,
          style: {
            background: '#000',
          },
        },
        {
          html: image12,
          style: {
            background: '#000',
          },
        },
        {
          html: image13,
          style: {
            background: '#000',
          },
        },
        {
          html: image14,
          style: {
            background: '#000',
          },
        },
        {
          html: image15,
          style: {
            background: '#000',
          },
        },
        {
          html: image16,
          style: {
            background: '#000',
          },
        },
        {
          html: image17,
          style: {
            background: '#000',
          },
        },
        {
          html: image18,
          style: {
            background: '#000',
          },
        },
        {
          html: image19,
          style: {
            background: '#000',
          },
        },
        {
          html: image20,
          style: {
            background: '#000',
          },
        },
        {
          html: image21,
          style: {
            background: '#000',
          },
        },
        {
          html: image22,
          style: {
            background: '#000',
          },
        },
        {
          html: image23,
          style: {
            background: '#000',
          },
        },
        {
          html: image24,
          style: {
            background: '#000',
          },
        },
        {
          html: image25,
          style: {
            background: '#000',
          },
        },
        {
          html: image26,
          style: {
            background: '#000',
          },
        },
        {
          html: image27,
          style: {
            background: '#000',
          },
        },
        {
          html: image28,
          style: {
            background: '#000',
          },
        },
      ],
      options: {
        effect: 'slide',
        loop: true,
        timingFunction: 'ease',
        autoplay: 5000,
      },
    };
  },
  components: {
    slider,
    slideritem,
  },
};
</script>

<style scoped>
.welcome {
  width: 100%;
  background: #000000; /* RED */

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
  margin: 0;
}

.slider {
  margin: 0 auto;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.arrow {
  position: sticky;
  color: white;
  text-decoration: none;
  text-align: center;
  top: 90%;
}
.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}
.image-fill {
  width: 100%;
  max-width: 600px;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
</style>
