<template>
  <div class="video">
    <div style="padding:56.25% 0 0 0;position:relative;">
      <iframe :src="getSource()"
      style="position:absolute;top:0;left:0;width:100%;height:100%;"
      frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Video',
  props: {
    vid: {
      type: Number,
      required: true,
    },
  },
  methods: {
    getSource() {
      return 'https://player.vimeo.com/video/'.concat(this.vid,
        '?title=0&byline=0&portrait=0');
    },
  },
};
</script>

<style scoped>
.video {
  padding-top: 40px;
  padding-bottom: 0;
  position: relative;
  background: #800000; /* RED */
}
</style>
