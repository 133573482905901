<template>
  <div class="home">
    <Welcome/>
    <WeddingDate/>
    <!-- <RSVP :code='code' v-show="dateInfo.isRsvpEnabled && isSignedIn && isValidCode"/> -->
    <RSVPTypeform v-show="dateInfo.isRsvpEnabled && isSignedIn && isValidCode"/>
    <FloorPlan :code='code' v-show="dateInfo.isWeddingDay && isSignedIn && isValidCode"/>
    <NewMessage :code='code' v-show="dateInfo.isWeddingDay && isSignedIn && isValidCode"/>
    <Messages :code='code' v-show="dateInfo.isWeddingDay && isSignedIn && isValidCode"/>
    <ThankYou :code='code' v-show="postWedding() && isSignedIn && isValidCode"/>
    <!-- <Video :vid='253082643' v-show="postWedding()"/> -->
    <Video :vid='655869400'/>
    <Agenda v-show="!postWedding()"/>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Welcome from '@/components/Welcome.vue';
import WeddingDate from '@/components/WeddingDate.vue';

// Pre wedding (Welcome, WeddingDate, RSVP, Video, Agenda)
// import RSVP from '@/components/RSVP.vue';
import RSVPTypeform from '@/components/RSVPTypeform.vue';

// Wedding day (Welcome, WeddingDate, FloorPlan, NewMessage,
// Messages, Video, Agenda)
import FloorPlan from '@/components/FloorPlan.vue';
import NewMessage from '@/components/NewMessage.vue';
import Messages from '@/components/Messages.vue';

// Post wedding (Welcome, WeddingDate, ThankYou, Video)
import ThankYou from '@/components/ThankYou.vue';

import Video from '@/components/Video.vue';
import Agenda from '@/components/Agenda.vue';

import Footer from '@/components/Footer.vue';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Home',
  components: {
    Welcome,
    WeddingDate,
    // RSVP,
    RSVPTypeform,
    FloorPlan,
    NewMessage,
    Messages,
    ThankYou,
    Video,
    Agenda,
    Footer,
  },
  props: {
    code: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters({ dateInfo: 'dateInfo', isSignedIn: 'isSignedIn', isValidCode: 'isValidCode' }),
  },
  methods: {
    ...mapActions(['validateCode', 'calculateDateInfo', 'signInAnonymously']),
    postWedding() {
      return !this.dateInfo.isWeddingDay && this.dateInfo.weddingDateDiff < 0;
    },
  },
  created() {
    this.signInAnonymously();
    this.calculateDateInfo();
  },
  beforeUpdate() {
    if (this.isSignedIn && this.isValidCode === false) {
      this.validateCode(this.code);
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    });
  },
};
</script>

<style scoped>
.home {
  height: 100%;
  padding: 0;
  margin: 0;
}
</style>
