<template>
  <div class="floor-plan">
    <p>จ๊ะเอ๋กับหนึ่งขอบคุณ <a class="name" v-show="hasUser()">{{user.name}}</a>
มากๆ เลยนะคะที่ให้เกียรติมาร่วมงาน</p>
    <!-- <div v-show="hasFloorPlan()">
      <p>เชิญร่วมรับประทานอาหารโต๊ะหมายเลข</p>
      <div class="circle tableCode" :style=floorPlan.tableColor>{{floorPlan.tableCode}}</div>
      <img class="floor-plan-img" src="../assets/dinner-table.svg">
    </div> -->
    <div>
      <img class="floor-plan-img" src="../assets/dinner-table.svg" style="margin-top:16px">
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'FloorPlan',
  props: ['code'],
  computed: mapGetters({ user: 'user', floorPlan: 'floorPlan' }),
  methods: {
    ...mapActions(['fetchUser', 'fetchFloorPlan']),
    hasUser() {
      return this.user.name !== '';
    },
    hasFloorPlan() {
      return this.floorPlan.tableCode !== '' && this.floorPlan.tableColor !== '';
    },
  },
  created() {
    this.fetchUser(this.code);
    this.fetchFloorPlan(this.code);
  },
};
</script>

<style scoped>
.floor-plan {
  padding: 5%;
  padding-bottom: 0;
  background: #800000; /* RED */
  font-family: 'Kanit', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
}
p {
  color: #fff;
}
.name {
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  color: #fff;
}
.circle {
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 50%;
  text-align: center;
}
.tableCode {
  font-family: 'Kanit', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 70px;
  margin: 20px auto;
}

.floor-plan-img {
  width: 100%;
  max-width: 600px;
  border-radius: 8px;
}
</style>
