<template>
  <div class="image-preview overlay">
    <font-awesome-icon
        class="close-button"
        v-on:click="onClose"
        icon="times"
        size="2x"
      />
    <img class="canvas-preview" v-bind:src="imageUrl">
  </div>
</template>

<script>

export default {
  name: 'ImagePreview',
  props: ['imageUrl'],
  methods: {
    onClose(e) {
      e.preventDefault();
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.image-preview {
  background: rgba(0, 0, 0, 0.5);
  margin: 0;
  overflow: hidden;
}
.overlay {
  z-index: 1;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.close-button {
  position: absolute;
  top: 0;
  right: 8px;
  color: #ffffff;
  padding: 8px 0;
  width: 32px;
  height: 32px;
  z-index: 100;
  pointer-events: all;
}
.canvas-preview {
 height: 100%;
 width: 100%;
 object-fit: contain;
}
</style>
