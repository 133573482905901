<template>
  <div class="message">
      <div class="description">
          <p class="description-text">{{name}}</p>
          <p class="description-text">{{getFormattedTime()}}</p>
      </div>
      <div class="bubble" v-if="text.length > 0" v-html="getTextHtml()"></div>
      <div class="bubble" v-else>
        <img class="canvas-preview" v-bind:src="imageUrl" v-on:click="showImagePreview">
        <ImagePreview
          :imageUrl="imageUrl"
          v-show="isShowImagePreview"
          @close="hideImagePreview"
        />
      </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { mapActions } from 'vuex';
import ImagePreview from '@/components/ImagePreview.vue';

export default {
  name: 'Message',
  props: ['name', 'updatedAt', 'text', 'imagePath'],
  components: {
    ImagePreview,
  },
  data() {
    return {
      imageUrl: null,
      isShowImagePreview: false,
    };
  },
  methods: {
    ...mapActions(['getCanvasUrl']),
    getFormattedTime() {
      dayjs.extend(relativeTime);
      return dayjs(this.updatedAt).fromNow().replace('a few seconds ago', 'just now');
    },
    getTextHtml() {
      return this.text.replace(/(?:\r\n|\r|\n)/g, '<br>');
    },
    getImageUrl() {
      if (this.imagePath === undefined || this.imagePath === null) { return; }
      this.getCanvasUrl(this.imagePath).then((url) => {
        this.imageUrl = url;
      });
    },
    showImagePreview() {
      this.isShowImagePreview = true;

      // When the modal is shown, we want a fixed body
      const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}`;
    },
    hideImagePreview() {
      this.isShowImagePreview = false;

      // When the modal is hidden...
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0', 0) * -1);
    },
  },
  created() {
    this.getImageUrl();
  },
};
</script>

<style scoped>
.message {
    display: flex;
    max-width: 600px;
    text-align: right;
    background-image: url('../assets/bubble-tail.svg');
    background-repeat: no-repeat;
    background-position: right top;
    margin-bottom: 8px;
    font-family: 'Kanit', sans-serif;
    margin-left: auto;
    margin-right: auto;
}
.description-text {
    width: 85px;
    height: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: right;
}
.description {
    margin-left: auto;
    display: block;
    text-align: right;
    color: #fff;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    margin-right: 8px;
}
.bubble {
    display: block;
    margin-right: 12px;
    padding: 2px 16px;
    max-width: 200px;
    min-height: 16px;
    background: #E8A0AE;
    border-radius: 16px;
    text-align: right;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    color: #000;
}
.canvas-preview {
  background: #ffffff;
  width: 100%;
  margin: 12px 0px 6px 0px;
  border-radius: 16px;
}
</style>
