<template>
  <div class="home">
    <Welcome/>
    <WeddingDate/>
    <ThankYou :code='code' v-show="isSignedIn && isValidCode"/>
    <Video :vid='655869400'/>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Welcome from '@/components/Welcome.vue';
import WeddingDate from '@/components/WeddingDate.vue';
import ThankYou from '@/components/ThankYou.vue';
import Video from '@/components/Video.vue';
import Footer from '@/components/Footer.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'HomePostWedding',
  components: {
    Welcome,
    WeddingDate,
    ThankYou,
    Video,
    Footer,
  },
  props: {
    code: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters({ isSignedIn: 'isSignedIn', isValidCode: 'isValidCode' }),
  },
  methods: {
    ...mapActions(['validateCode', 'signInAnonymously']),
  },
  created() {
    this.signInAnonymously();
  },
  beforeUpdate() {
    if (this.isSignedIn && this.isValidCode === false) {
      this.validateCode(this.code);
    }
  },
};
</script>

<style scoped>
.home {
  height: 100%;
  padding: 0;
  margin: 0;
}
</style>
