<template>
  <div class="thank-you">
      <div class="paper">
        <img id="header" src="../assets/thank-you-header.svg" />
        <br>
        <div class="paragraph">
            <p class="name" v-show="hasUser()">ถึง {{user.name}}</p>
            <p>
                จ๊ะเอ๋กับหนึ่งขอบคุณมากๆ เลยนะคะ
                ที่ร่วมเป็นส่วนหนึ่งในความทรงจำ ที่แสนพิเศษของเราสองคน
    <br>ขอบคุณสำหรับ ทุกข้อความอวยพร ความรักและความปรารถนาดีที่มอบให้เราค่ะ
    <br><br>
    หากมีความผิดพลาด หรือขาดตกบกพร่องประการใด เราสองคนต้องขออภัยมา ณ ที่นี้ด้วยนะคะ
    <br><br>
    สุดท้ายนี้ขอให้มีความสุขมากๆ เช่นเดียวกัน
            </p>
        </div>
        <br>
        <img id="footer" src="../assets/thank-you-footer.svg" />
        <img id="logo" src="../assets/logo.svg" />
      </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ThankYou',
  props: ['code'],
  computed: mapGetters({ user: 'user' }),
  methods: {
    ...mapActions(['fetchUser']),
    hasUser() {
      return this.user.name !== '';
    },
  },
  created() {
    this.fetchUser(this.code);
  },
};
</script>

<style scoped>
.thank-you {
    margin: 0 auto;
    max-width: 600px;
    font-family: 'Kanit', sans-serif;
    color: #000;
}
.paper {
    padding: 16px;
    background-image: linear-gradient(#FFC7D2, #FFFFFF);
    border-radius: 8px;
    margin: 5%
}
.name {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: left;
}
.paragraph {
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    display: block;
    width: 100%;
}
#header {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
#footer {
    margin: 0 auto;
    display: block;
}
#logo {
    display: block;
    margin-left: auto;
    margin-right: 0px;
}
</style>
