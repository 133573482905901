var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"drawing-canvas overlay"},[_c('font-awesome-icon',{staticClass:"close-button",attrs:{"icon":"times","size":"2x"},on:{"click":_vm.onClose}}),_c('div',{staticClass:"tools",style:({
      top: _vm.configTools.top + 'px',
      left: _vm.configTools.left + 'px',
      width: _vm.configTools.width + 'px',
      height: _vm.configTools.height + 'px',
    })},[_c('div',{class:{
        'manage-canvas-portrait': _vm.isPortrait,
        'manage-canvas-landscape': !_vm.isPortrait
      }},[_c('vue-slider',{class:{
          'slider-portrait': _vm.isPortrait,
          'slider-landscape': !_vm.isPortrait
        },attrs:{"width":_vm.isPortrait ? 100 : 8,"height":!_vm.isPortrait ? 100 : 8,"min":1,"max":25,"tooltip":'active',"tooltip-placement":_vm.isPortrait ? ['top'] : ['left'],"tooltip-formatter":'{value}px',"direction":_vm.isPortrait ? 'ltr' : 'ttb'},model:{value:(_vm.lineSize),callback:function ($$v) {_vm.lineSize=$$v},expression:"lineSize"}}),_c('v-swatches',{staticClass:"color-button",attrs:{"popover-x":_vm.isPortrait ? 'left' : 'right',"popover-y":_vm.isPortrait ? 'top' : 'bottom',"swatches":"text-advanced","trigger-style":{
          width: '32px',
          height: '32px',
          borderRadius: '4px'
        },"swatch-style":{
          height: '24px',
          width: '24px'
        }},model:{value:(_vm.penColor),callback:function ($$v) {_vm.penColor=$$v},expression:"penColor"}}),_c('font-awesome-icon',{staticClass:"pen-button",class:{selected: _vm.isBrush(), inactiveButton: !_vm.isBrush()},attrs:{"icon":"pen","size":"1x"},on:{"click":_vm.onSelectPen}}),_c('font-awesome-icon',{staticClass:"eraser-button",class:{selected: !_vm.isBrush(), inactiveButton: _vm.isBrush()},attrs:{"icon":"eraser","size":"1x"},on:{"click":_vm.onSelectEraser}}),_c('font-awesome-icon',{staticClass:"undo-button",class:{ 'tools-disabled': (_vm.lines.length === 0) },attrs:{"icon":"undo-alt","size":"1x"},on:{"click":_vm.handleUndo}}),_c('font-awesome-icon',{staticClass:"redo-button",class:{ 'tools-disabled': (_vm.undoLines.length === 0) },attrs:{"icon":"redo-alt","size":"1x"},on:{"click":_vm.handleRedo}})],1),_c('div',{staticClass:"manage-version center"},[_c('button',{staticClass:"button",on:{"click":_vm.onUpload}},[_vm._v("บันทึกการ์ดอวยพร")]),_c('button',{staticClass:"button",attrs:{"disabled":_vm.lines.length === 0},on:{"click":_vm.onClear}},[_vm._v(" ลบรูปการ์ดที่เขียนไว้ ")])])]),(_vm.isLoading)?_c('div',{staticClass:"loading overlay"},[_c('h1',{staticClass:"center"},[_vm._v("โปรดรอสักครู่")])]):_vm._e(),_c('v-stage',{ref:"stage",staticClass:"canvas-stage",class:{ 'canvas-portrait': _vm.isPortrait, 'canvas-landscape': !_vm.isPortrait },attrs:{"config":_vm.configKonva},on:{"mousedown":_vm.handleTouchStart,"touchstart":_vm.handleTouchStart,"mouseup":_vm.handleTouchEnd,"touchend":_vm.handleTouchEnd,"mousemove":_vm.handleTouchMove,"touchmove":_vm.handleTouchMove}},[_c('v-layer',{ref:"layer0"},[_c('v-image',{ref:"backgroundImage"})],1),_c('v-layer',{ref:"layer"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }