<template>
  <div class="agenda">
    <div>
      <img class="header" src="../assets/agenda.svg"/>
    </div>
    <div>
      <img class="image" src="../assets/timeline.svg"/>
    </div>
    <div>
      <img class="separator" src="../assets/separator.svg"/>
    </div>
    <div>
      <a href="https://goo.gl/maps/uynXNCDgFD1Bogxf9" target="_blank">
        <img class="image navigation" src="../assets/navigate-to-home.svg"/>
      </a>
    </div>
    <div>
      <a href="https://g.page/thebedvacation" target="_blank">
        <img class="image navigation" src="../assets/navigate-to-hotel.svg"/>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Agenda',
};
</script>

<style scoped>
.agenda {
  padding-top: 40px;
  padding-bottom: 0;
  position: relative;
  background: #800000; /* RED */
}
.header {
  padding-bottom: 20px;
}
.image {
  width: 100%;
  max-width: 600px;
}
.separator {
  padding-top: 10px;
  padding-bottom: 10px;
}
.navigation {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
