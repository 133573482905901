<template>
  <div class="new-message">
    <p>{{getFormDescription()}}</p>
    <form class='form-container' @submit='onSubmit'>
      <div class='name'>
        <label for='name'>ชื่อ</label>
        <input id='name' placeholder='' type='text' v-model='formData.name'>
      </div>
      <div class='code'>
        <label for='code'>รหัสบัตรเชิญ</label>
        <input id='code'
        placeholder=''
        type='text'
        v-model='formData.invitationCode'
        @change='onValidateCode'
        required>
      </div>
      <div class='leave-message' v-if="formData.canvasPreviewUrl == null">
        <label for='leave-message'>ข้อความอวยพร</label>
        <textarea id='leave-message' placeholder='' rows="5"
         v-model='formData.message'/>
      </div>
      <div class="canvas-container" v-if="formData.canvasPreviewUrl != null">
        <font-awesome-icon class="remove-button"
          v-on:click="onRemoveCanvas"
          icon="trash-alt"
          size="1x"/>
        <img class="canvas-preview"
          v-bind:src="formData.canvasPreviewUrl"/>
      </div>
      <div>
        <button
          v-on:click="onShowDrawingCanvas"
          :disabled="formData.message.length !== 0"
        >
          กระดานเขียนคำอวยพร <font-awesome-icon icon="edit" size="1x" />
        </button>
        <button
        :disabled='!formData.isValid ||
        (formData.message.length === 0 && formData.canvasPath === null)'>
        ส่งถึงจ๊ะเอ๋กับหนึ่ง <font-awesome-icon icon="paper-plane" size="1x" />
        </button>
      </div>
    </form>
    <DrawingCanvas
      v-show="isDrawingModeEnabled"
      @close="onHideDrawingCanvas"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DrawingCanvas from '@/components/DrawingCanvas.vue';

export default {
  name: 'NewMessage',
  components: {
    DrawingCanvas,
  },
  props: ['code'],
  data() {
    return {
      isDrawingModeEnabled: false,
    };
  },
  computed: mapGetters({ formData: 'messageFormData', messages: 'messages' }),
  methods: {
    ...mapActions(['sendMessages', 'fetchMessages', 'validateCode', 'removeCurrentImage']),
    onSubmit(e) {
      e.preventDefault();
      this.sendMessages(this.formData);
    },
    onValidateCode(e) {
      e.preventDefault();
      this.validateCode(this.formData.invitationCode);
    },
    getFormDescription() {
      if (this.code === undefined) {
        return 'ใช้รหัสที่โต๊ะเพื่อร่วมแสดงความยินดีกับจ๊ะเอ๋และหนึ่งได้นะคะ';
      }
      if (this.formData.isValid) {
        if (this.messages.length === 0) {
          return 'ร่วมแสดงความยินดีกับจ๊ะเอ๋และหนึ่งได้นะคะ';
        }
        return 'จ๊ะเอ๋กับหนึ่งขอบคุณสำหรับคำอวยพรก่อนหน้านี้ ถ้าอยากส่งข้อความเพิ่มเติมยินดีเลยค่ะ';
      }
      return 'แย่จัง ! เราไม่รู้จักรหัสนี้ ลองสแกนใหม่อีกครั้ง หรือใช้รหัสที่โต๊ะเพื่อร่วมแสดงความยินดีกับจ๊ะเอ๋และหนึ่งได้นะคะ';
    },
    onShowDrawingCanvas(e) {
      e.preventDefault();
      this.isDrawingModeEnabled = true;

      // When the modal is shown, we want a fixed body
      const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}`;
    },
    onHideDrawingCanvas() {
      this.isDrawingModeEnabled = false;

      // When the modal is hidden...
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0', 0) * -1);
    },
    onRemoveCanvas(e) {
      e.preventDefault();
      this.removeCurrentImage();
    },
  },
  created() {
    this.fetchMessages(this.code);
  },
};
</script>

<style scoped>
.new-message {
  padding: 5%;
  padding-bottom: 0;
  background: #800000; /* RED */
  font-family: 'Kanit', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  color: #000;
}

p {
  margin: 10px 0;
  font-size: 24px;
  line-height: 36px;
  color: #fff;
}

.form-container {
  max-width: 340px;
  margin: 5px auto;
  position: left;
  border: gray 1px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  padding: 8px;
  text-align: left;
}

.form-container input,
.form-container textarea {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: 38px;
  line-height: 38px;
  padding-left: 5px;
  margin-bottom: 5px;
  padding-left: 8px;
}

.form-container textarea {
  height: 100px;
}

.form-container input,
.form-container label,
.form-container textarea {
  color: rgba(0, 0, 0, 0.8);
  width: 100%;
  box-sizing: border-box;
  font-family: 'Kanit', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
}

.form-container header {
  padding: 40px;
}

.name label,
.code label,
.leave-message label {
  display: block;
  margin-bottom: 5px;
  text-align: left;
  width: 100%;
}

.form-container button {
  height: 38px;
  border-radius: 19px;
  font-family: 'Kanit', sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
}
.form-container button {
  background: #800000;
  border: 1px solid transparent;
  color: #fff;
  width: 100%;
  margin-top: 10px;
}
.form-container button:active,
.form-container button:disabled {
  background: #BC8989;
  color: #CBADAD;
}
.canvas-container {
  margin-top: 10px;
  position: relative;
}
.canvas-preview {
  background: #ffffff;
  width: 100%;
  border-radius: 8px;
}
.remove-button {
  color: #ff0000;
  margin: 8px;
  position: absolute;
  right: 0px;
}
</style>
