import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowDown, faAngleDown, faPaperPlane, faTimes, faTrashAlt,
  faEraser, faPen, faEdit, faUndoAlt, faRedoAlt,
} from '@fortawesome/free-solid-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import vueHeadful from 'vue-headful';
import VueCountdown from '@chenfengyuan/vue-countdown';
import VueKonva from 'vue-konva';
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

library.add(faArrowDown, faAngleDown, faPaperPlane, faTwitter, faTimes,
  faTrashAlt, faEraser, faPen, faEdit, faUndoAlt, faRedoAlt);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('vue-headful', vueHeadful);
Vue.component(VueCountdown.name, VueCountdown);
Vue.use(VueKonva);
Vue.use(VuejsDialog);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
