<template>
  <div class="footer">
    <p>All rights reserved. © {{new Date().getFullYear()}}
      <br>
      Made with ❤️ by
      <a href="https://twitter.com/PattoMotto" target="_blank">PattoMotto <font-awesome-icon class="twitter-color" :icon="['fab', 'twitter']"/></a>
      and <a href="https://twitter.com/KettoMotto" target="_blank">KettoMotto <font-awesome-icon class="twitter-color" :icon="['fab', 'twitter']"/></a>
      </p>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped>
.footer {
  margin-top: 5%;
  color: #000;
  background: #E8A0AE;
  padding: 16;
  padding-bottom: 0;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  font-family: 'Saira', sans-serif;
}

p {
  padding-top: 8px;
  padding-bottom: 8px;
}

a {
  color: #000;
  text-decoration: none;
}

.twitter-color {
  color: #1DA1F2;
}
</style>
