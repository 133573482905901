<template>
  <div class="rsvp">
    <p class="greeting">รบกวนกรอกข้อมูลให้เรานิดนึงนะคะ</p>
    <div
    data-tf-widget="HlKpKsf0"
    data-tf-opacity="100"
    data-tf-chat style="width:100%;height:75vh;">
    </div>
  </div>
</template>

<!-- <script src="//embed.typeform.com/next/embed.js"></script> -->
<script>

export default {
  name: 'RSVPTypeform',
  mounted() {
    const typeformScript = document.createElement('script');
    typeformScript.setAttribute('src', 'https://embed.typeform.com/next/embed.js');
    document.head.appendChild(typeformScript);
  },
};
</script>

<style scoped>
.rsvp {
  padding: 5%;
  background: #800000; /* RED */
  font-family: 'Kanit', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  color: #000;
}

.greeting {
  font-size: 24px;
  line-height: 36px;
  color: #fff;
}

#hideMe {
    -moz-animation: cssAnimation 0s ease-in 5s forwards;
    /* Firefox */
    -webkit-animation: cssAnimation 0s ease-in 5s forwards;
    /* Safari and Chrome */
    -o-animation: cssAnimation 0s ease-in 5s forwards;
    /* Opera */
    animation: cssAnimation 0s ease-in 5s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}
@keyframes cssAnimation {
    to {
        width:0;
        height:0;
        overflow:hidden;
    }
}
@-webkit-keyframes cssAnimation {
    to {
        width:0;
        height:0;
        visibility:hidden;
    }
}
</style>
