<template>
  <div class="messages">
    <div v-for="message in getMessages()" :key="message.updatedAt">
      <Message
      :text="message.message"
      :imagePath="message.canvasPath"
      :name="message.name"
      :updatedAt="message.updatedAt"/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Message from '@/components/Message.vue';

export default {
  name: 'Messages',
  props: {
    code: {
      type: String,
      required: false,
    },
    showAllUsers: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Message,
  },
  computed: mapGetters({ messages: 'messages', allUsersMessages: 'allUsersMessages' }),
  methods: {
    ...mapActions(['bindMessages', 'bindAllUsersMessages']),
    getMessages() {
      return (this.showAllUsers ? this.allUsersMessages : this.messages)
        .filter(() => true);
    },
  },
  created() {
    if (this.showAllUsers) {
      this.bindAllUsersMessages(this.code);
    } else {
      this.bindMessages(this.code);
    }
  },
};
</script>

<style scoped>
.messages {
  padding: 5%;
  padding-top: 20px;
  background: #800000; /* RED */
  text-align: center;
}
</style>
