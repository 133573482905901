<template>
  <div class="wedding-date">
    <img id="date-img" src="../assets/wedding-date.svg" />
    <div id="countdown">
      <div v-if="dateInfo.isWeddingDay">Today is our wedding day</div>
      <countdown
        :time=dateInfo.weddingDateDiff
        v-else-if="dateInfo.weddingDateDiff>0"
      >
        <template slot-scope="props">
          {{ props.days }} days {{ props.hours }} hours left
        </template>
      </countdown>
      <div v-else>Our special day</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'WeddingDate',
  computed: {
    ...mapGetters({ dateInfo: 'dateInfo' }),
  },
};
</script>

<style scoped>
.wedding-date {
  background: #800000; /* RED */
  background-image: linear-gradient(#000000, #800000);
  padding: 0;
  margin: 0;
}
#date-img {
  max-width: 400px;
  margin-top: 15px;
}
#countdown {
  font-family: 'Saira', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
}
</style>
