import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import HomeWeddingDay from '../views/HomeWeddingDay.vue';
import HomePostWedding from '../views/HomePostWedding.vue';
import AllMessages from '../views/AllMessages.vue';
import NotFound from '../views/NotFound.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/all-messages/:code',
    name: 'AllMessages',
    component: AllMessages,
    props: true,
  },
  {
    path: '/post-wedding',
    name: 'HomePostWedding',
    component: HomePostWedding,
  },
  {
    path: '/post-wedding/:code',
    name: 'HomePostWeddingWithCode',
    component: HomePostWedding,
    props: true,
  },
  {
    path: '/wedding-day',
    name: 'HomeWeddingDay',
    component: HomeWeddingDay,
  },
  {
    path: '/wedding-day/:code',
    name: 'HomeWeddingDayWithCode',
    component: HomeWeddingDay,
    props: true,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/:code',
    name: 'HomeWithCode',
    component: Home,
    props: true,
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
